import React from 'react'
import {
    Container,
    Box,
    Text,
    Heading
} from '@chakra-ui/react'

import { useStaticQuery, graphql } from 'gatsby'

import News from './News'

const NewsBlock = () => {
   
    const data = useStaticQuery(graphql`
    {
      allPrismicNews {
        edges {
          node {
            data {
              title {
                text
              }
              event_location {
                text
              }
              event_date {
                text
              }
              content {
                raw
              }
            }
            url
            uid
          }
        }
      }
    }
  `)

  const newsData = data.allPrismicNews.edges;

  console.log(newsData)
      return (
        <>
             <Container maxWidth="container.xl" my={20} p={{sm: 4, md: 8}}>
            <Box w={{sm: '100%', md: '100%'}} id="news">
              {/* <Text variant="sub-head" textAlign="center">
                  Iris Biomedical News
              </Text> */}
            <Heading as="h2" fontWeight="600" textAlign="center" mb="2">
              Iris Biomedical News
            </Heading>
            {/* <Text fontSize="1.5rem" color="steel.700" fontWeight="300" textAlign="center">
                Check the list below for a conference or event near you.
            </Text> */}
            </Box>
            <Container maxW="container.lg">
                {newsData.map((item) => {
                    return (
                        <News
                          meetingTitle={item.node.data.title.text}
                          meetingDate={item.node.data.event_date.text}
                          //meetingLink={meetingItem.meeting_link.url}
                          //meetingLinkText={meetingItem.meeting_link_text.text}
                          postLink={item.node.url}
                        />
                    )
                })}
            
            </Container>
        </Container>
        </>
    )
}

export default NewsBlock