import React from "react"
import Layout from '../components/layout'

import Seo from "../components/seo"
import CustomerSpotlight from "../components/CustomerSpotlight/CustomerSpotlight"
import ConferencesBlock from "../components/Conferences/ConferencesBlock"
import NewsBlock from "../components/Conferences/NewsBlock"

const NewsEventsPage = (props) => {
 
  return (
    
  <Layout
    annoucementDisplay="none"
  >
  <Seo title="Iris Biomedical | News &amp; Events" />

    <CustomerSpotlight />

    <NewsBlock />

    <ConferencesBlock />


  </Layout>
  )
}


export default NewsEventsPage
