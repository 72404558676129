import React from "react"
import { Box, Container, Heading, Flex, Text } from "@chakra-ui/react"
import { graphql, useStaticQuery } from 'gatsby'
import CtaButton from "../CtaButton/CtaButton"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { RichText } from "prismic-reactjs"

const CustomerSpotlight = () => {
    const data = useStaticQuery(graphql`
    {
        allPrismicCustomerSpotlight {
          edges {
            node {
              data {
                case_study_date {
                  text
                }
                case_study_link {
                  url
                  target
                }
                case_study_title {
                  text
                }
                spotlight_content {
                  raw
                }
                title {
                  text
                }
                case_study_person {
                  text
                  raw
                }
                featured_image {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        formats: AUTO
                        aspectRatio: 1
                        layout: FULL_WIDTH
                        placeholder: BLURRED
                      )
                    }
                  }
                  alt
                }
                person_s_title {
                  text
                  raw
                }
              }
            }
          }
        }
      }
  `)

  const document = data.allPrismicCustomerSpotlight.edges[0].node.data

  return (
    <>
            <Container maxWidth="container.sm" my={20} p={8}>
            <Box w={{sm: '100%', md: '100%'}} id="customer-spotlight">
            <Text variant="sub-head" textAlign="center">
                {document.case_study_date.text}
            </Text>
            <Heading as="h1" textAlign="center" variant="page-title" mb="4">
            {document.title.text}
      </Heading>
      <Heading as="h2" textAlign="center" variant="sub-title" mb="6">
      {document.case_study_title.text}
      </Heading>
            {/* <Heading as="h2" fontWeight="600" textAlign="center">
                {document.title.text}
            </Heading>
            <Text fontSize="1.5rem" color="steel.700" fontWeight="300" textAlign="center">
                {document.case_study_title.text}
            </Text> */}
            <Box textAlign="center">
            <CtaButton
                variant="ctaButtonBlue" 
                btnLink={document.case_study_link.url}
                btnText={"Read Full Case Study"} 
                target={document.case_study_link.target}
                rel="noreferrer noopener"
                />
            </Box>
            </Box>
        </Container>
        <Container maxWidth="container.lg" my={20} p={8}>
            <Flex display={{sm: 'none', md: 'flex'}}>
                <Box w={{sm: '100%', md: '25%'}}
                    mr={{sm: 0, md: '2rem'}}
                    >
                    <Box rounded="xl"
                    boxShadow="base"
                    bg="white"
                    overflow="hidden">
                      <GatsbyImage image={getImage(document.featured_image.localFile)} alt={document.featured_image.alt}/>
                </Box>
                </Box>
                <Box w={{sm: '100%', md: '75%'}}>
                    <Text color="shadow.500">
                        <RichText render={document.spotlight_content.raw} />
                    </Text>

                    {/* In production, these styles weren't being applied when using the Chakra <Text/> component and the URL contained a trailing slash */}
                    <Box color="steel.700" fontSize="1.25rem" fontWeight="700" mb="0" className="customer-spotlight-person">
                        <RichText render={document.case_study_person.raw}/>
                    </Box>
                    <Box color="steel.700" className="customer-fix">
                      <RichText render={document.person_s_title.raw} />
                    </Box>

                    {/* In production, these styles weren't being applied when using the Chakra <Text/> component and the URL contained a trailing slash */}
                    {/* <p mb="0" fontWeight="700" color="steel.700" fontSize="1.25rem"
                     style={{
                      color: '#004f7e',
                      fontWeight: 700,
                      fontSize: '1.25rem',
                      marginBottom: 0
                    }}>
                        {document.case_study_person.text}
                    </p>
                    <p color="steel.700" style={{
                      color: '#004f7e',
                    }}>
                        {document.person_s_title.text}
                    </p> */}
                    <Box>
                        <CtaButton variant="ctaButtonOutline" 
                            btnLink={document.case_study_link.url}
                            btnText={"Read Full Case Study"} 
                            target={document.case_study_link.target}
                            rel="noreferrer noopener"
                        />
                    </Box>
                </Box>
            </Flex> 

            <Box w={{sm: '100%', md: '75%'}}  display={{sm: 'block', md: 'none'}}>
                    <Text color="shadow.500">
                        <RichText render={document.spotlight_content.raw} />
                    </Text>
                
            <Flex>
                <Box w='40%'
                    mr='2'
                    >
                    <Box rounded="xl"
                    boxShadow="base"
                    bg="white"
                    overflow="hidden">
                      <GatsbyImage image={getImage(document.featured_image.localFile)} alt={document.featured_image.alt}/>
                </Box>
                </Box>
                <Box>
                <Box color="steel.700" fontSize="1.25rem" fontWeight="700" mb="0" className="customer-spotlight-person">
                        <RichText render={document.case_study_person.raw}/>
                    </Box>
                    <Box color="steel.700" className="customer-fix">
                      <RichText render={document.person_s_title.raw} />
                    </Box>
                    <Box>
                        <CtaButton variant="ctaButtonOutline" 
                            btnLink={document.case_study_link.url}
                            btnText={"Read Full Case Study"} 
                            target={document.case_study_link.target}
                            rel="noreferrer noopener"
                        />
                    </Box>
                </Box>
              </Flex>            
              </Box>
        </Container>
        <Container maxWidth="container.lg">
        <hr />
        </Container>
        </>
  )
}

export default CustomerSpotlight
